import styled from 'styled-components'

const Close = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  cursor: pointer;

  @media (max-width: 380px) {
    right: 10px;
  }
`

export default Close
