import styled from 'styled-components'

const loadProps = ({ visible }) => {
  if (visible) {
    return `
      opacity: 1;
      pointer-events: all;
      z-index: 3;
    `
  }

  return `
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  `
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 2;
  transition: 0.4s opacity ease;
  ${props => loadProps(props)}
`

export default Overlay
