import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import valuesBg from '../../assets/images/values.png'
import Wrap from '../wrap/wrap'
import Title from '../title/title'
import RawTextBase from '../rawText/rawText'
import Texts from '../texts/texts'
import colors from '../../theme/colors'

const StyledValues = styled.section`
  width: 100%;
  margin-bottom: 95px;
`

const ResponsiveText = styled(Texts)`
  position: relative;
  z-index: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
`

const Background = styled.div`
  width: 1140px;
  margin: 0 auto;
  background: url(${valuesBg}) no-repeat center;
  background-size: cover;
  position: relative;
  height: 800px;
  padding: 90px 130px;
  overflow: hidden;

  &:before {
    content: '';
    width: 95%;
    height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(255, 255, 255, 0.4);
    z-index: 0;
  }

  @media (max-width: 1160px) {
    width: 100%;
  }

  @media (max-width: 650px) {
    padding: 40px 20px;

    &:before {
      display: none;
    }
  }
`

const RawText = styled(RawTextBase)`
  ul {
    padding-left: 15px;
    margin-top: 10px;
  }
`

const Values = ({ t }) => (
  <StyledValues id={t('nav.1.hash')}>
    <Wrap>
      <Title margin="0 0 20px 0">{t('values.title')}</Title>
      <Background>
        <ResponsiveText width="370px">
          {t('values.sections').map(block => (
            <Texts key={Math.random()}>
              <Title color={colors.white}>{block.title}</Title>
              {block.paragraphs.map(p => (
                <RawText
                  align="left"
                  color={colors.white}
                  key={Math.random()}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              ))}
            </Texts>
          ))}
        </ResponsiveText>
      </Background>
    </Wrap>
  </StyledValues>
)

Values.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Values)
