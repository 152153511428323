import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import colors from '../../theme/colors'

const StyledLink = styled.a`
  color: ${colors.grey};
  font-size: 15px;
  font-weight: 600;
  transition: 0.4s color ease;

  &:hover {
    color: ${props => (props.headerIsOnTop ? colors.white : colors.black)};
  }

  & + a {
    margin-left: 30px;
  }

  @media (max-width: 1000px) {
    & + a {
      margin-left: 0;
      margin-top: 30px;
    }
  }
`

const Link = ({ children, appStore, ...rest }) => (
  <StyledLink onClick={() => appStore.closeMenu()} headerIsOnTop={appStore.headerIsOnTop} {...rest}>
    {children}
  </StyledLink>
)

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  appStore: PropTypes.object.isRequired,
}

export default inject('appStore')(observer(Link))
