import React from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import Links from '../links/links'
import LangSelect from '../langSelect/langSelect'
import colors from '../../theme/colors'

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`

const Hamburguer = styled.div`
  width: 100%;
  height: 2px;
  background: ${props => (!props.headerIsOnTop || props.isOpen ? colors.black : colors.white)};
  position: relative;
  transition: 0.4s background ease;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${props => (!props.headerIsOnTop || props.isOpen ? colors.black : colors.white)};
    width: 100%;
    height: 100%;
    transition: 0.4s transform ease;
  }

  &:after {
    transform: translateY(7px);
  }

  &:before {
    transform: translateY(-7px);
  }

  ${props => props.isOpen
    && `
    background: transparent;

    &:before {
      transform: translateY(0) rotate(45deg);
    }
    &:after {
      transform: translateY(0) rotate(-45deg);
    }
  `}
`

const HamburguerButton = styled.button`
  width: 25px;
  height: 25px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1001px) {
    display: none;
  }
`

@inject('appStore')
@observer
class Nav extends React.Component {
  static propTypes = {
    appStore: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.localStorage.clear()
  }

  toggleMenu = () => {
    const { appStore } = this.props

    appStore.toggleMenu()
  }

  render() {
    const { appStore } = this.props

    return (
      <StyledNav>
        <Links />
        <HamburguerButton onClick={this.toggleMenu}>
          <Hamburguer isOpen={appStore.menuIsOpen} headerIsOnTop={appStore.headerIsOnTop} />
        </HamburguerButton>
        <LangSelect />
      </StyledNav>
    )
  }
}

export default Nav
