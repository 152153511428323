import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledList = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.align};
  flex-wrap: wrap;
`

const List = ({ children, ...props }) => <StyledList {...props}>{children}</StyledList>

List.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  align: PropTypes.string,
}

List.defaultProps = {
  align: 'space-between',
}

export default List
