import React from "react";
import styled from "styled-components";
import { withNamespaces } from "react-i18next";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import colors from "../../../theme/colors";
import Overlay from "../overlay";
import Texts from "../../texts/texts";
import Title from "../../title/title";
import RawText from "../../rawText/rawText";
import Close from "../close";
import { CloseIcon } from "../../icons/icons";
import PlaceholderBase from "../../member/imgPlaceholder";

const Picture = styled.div`
  width: 275px;
  min-width: 275px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
  }
`;

const Placeholder = styled(PlaceholderBase)`
  width: 275px;
  height: 414px;
`;

const Modal = styled.div`
  width: 855px;
  min-height: 414px;
  max-height: 97vh;
  background: ${colors.white};
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  @media (max-width: 855px) {
    width: calc(100% - 20px);

    ${Picture}, ${Placeholder} {
      display: none;
    }
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  height: 100%;
  flex-grow: 1;

  @media (max-width: 374px) {
    padding: 35px 10px;
  }
`;

@inject("appStore")
@observer
class MemberModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    appStore: PropTypes.object.isRequired
  };

  overlay = React.createRef();

  componentDidUpdate() {
    const { appStore } = this.props;
    if (appStore.memberModalIsVisible) this.overlay.current.focus();
  }

  close = () => {
    const { appStore } = this.props;

    appStore.setMemberVisibility(false);
  };

  stop = e => {
    e.stopPropagation();
  };

  keyPressed = e => {
    if (e.key === "Escape") this.close();
  };

  render() {
    const { t, appStore } = this.props;

    const activeMember = t("team.members").find(
      member => member.id === appStore.activeMemberId
    );

    return appStore.activeMemberId ? (
      <Overlay
        onKeyUp={this.keyPressed}
        tabIndex="0"
        onClick={this.close}
        ref={this.overlay}
        visible={appStore.memberModalIsVisible}
      >
        <Modal onClick={this.stop}>
          <Close onClick={this.close}>
            <CloseIcon />
          </Close>
          {activeMember.img ? (
            <Picture>
              <img
                src={require(`../../../assets/members/${activeMember.img}`)}
                alt={t("team.member.stdAlt")}
              />
            </Picture>
          ) : (
            <Placeholder />
          )}
          <TextContent>
            <Texts noMargin margin="0 0 35px">
              <Title intern={activeMember.intern} fontType={1}>
                {activeMember.name}
              </Title>

              <RawText intern={activeMember.intern} align="left">
                {activeMember.title}
              </RawText>

              {activeMember.oab && typeof activeMember.oab === "string" && (
                <RawText
                  intern={activeMember.intern}
                  align="left"
                  color={colors.lightGrey}
                >
                  {`OAB/${activeMember.region} nº ${activeMember.oab}`}
                </RawText>
              )}
              {activeMember.oab &&
                typeof activeMember.oab !== "string" &&
                activeMember.oab.map(oab => (
                  <RawText align="left" color={colors.lightGrey}>
                    {`OAB/${oab}`}
                  </RawText>
                ))}
            </Texts>

            <RawText align="left">{activeMember.description}</RawText>

            {activeMember.area ? (
              <Texts noMargin margin="20px 0 0">
                <RawText intern={activeMember.intern} align="left">
                  <b>{t("team.area_label")}</b>
                </RawText>
                <RawText>{activeMember.area}</RawText>
              </Texts>
            ) : null}

            {activeMember.plus ? (
              <Texts
                noMargin
                margin="0 0 20px"
                style={{ overflow: "auto", "max-height": "150px" }}
              >
                <RawText intern={activeMember.intern} align="left">
                  <b>{t("team.plus_label")}</b>
                </RawText>
                {activeMember.plus.map(item => (
                  <RawText align="left" key={Math.random()}>
                    {item}
                  </RawText>
                ))}
              </Texts>
            ) : null}

            {activeMember.email ? (
              <RawText intern={activeMember.intern} align="left">
                <a href={`mailto:${activeMember.email}`}>
                  <b>{activeMember.email}</b>
                </a>
              </RawText>
            ) : null}
          </TextContent>
        </Modal>
      </Overlay>
    ) : null;
  }
}

export default withNamespaces()(MemberModal);
