import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'
import Texts from '../texts/texts'
import RawText from '../rawText/rawText'
import * as icons from '../icons/icons'

const Item = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin: 15px;

  svg {
    fill: ${colors.lightGrey};
    width: 32px;
    height: 30px;
  }

  @media (max-width: 540px) {
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }
`

const ResponsiveText = styled(Texts)`
  @media (max-width: 520px) {
    width: 100%;
  }
`

const AtuationItem = ({ icon, title, description }) => (
  <Item>
    {icons[icon]()}
    <ResponsiveText noMargin width="445px">
      <RawText>
        <b>{title}</b>
      </RawText>
      <RawText>{description}</RawText>
    </ResponsiveText>
  </Item>
)

AtuationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default AtuationItem
