import React from 'react'
import Raven from 'raven-js'
import PropTypes from 'prop-types'

import config from '../../services/config'

Raven.config(config.sentry.dsn).install()

export default class extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  }

  componentDidCatch(error, errorInfo) {
    if (config.sentry.enabled) {
      Raven.captureException(error, { extra: errorInfo })
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}
