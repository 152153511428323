import { debugBanner } from "../pushstart";

const {
  REACT_APP_BUILD_TIME,
  REACT_APP_COMMIT_HASH,
  REACT_APP_SENTRY_DSN,
  REACT_APP_GOOGLE_ANALYTICS,
  REACT_APP_API_URL,
  NODE_ENV
} = process.env;

const {
  version,
  name,
  description,
  proxy: root
} = require("../../package.json");

debugBanner({
  version,
  env: NODE_ENV,
  build: String(REACT_APP_BUILD_TIME)
    .split(":")
    .slice(0, -1)
    .join("-")
    .replace(/\//gi, "-"),
  hash: REACT_APP_COMMIT_HASH
});

export default {
  version,
  name,
  description,
  root,

  api: REACT_APP_API_URL,
  ga: REACT_APP_GOOGLE_ANALYTICS,

  sentry: {
    enabled: !(process.env.NODE_ENV === "production"),
    dsn: REACT_APP_SENTRY_DSN
  }
};
