import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import RawText from '../rawText/rawText'
import Title from '../title/title'
import Wrap from '../wrap/wrap'
import List from '../list/list'
import PressItem from '../pressItem/pressItem'
import FlexBase from '../flex/flex'

const StyledPress = styled.section`
  width: 100%;
`

const Flex = styled(FlexBase)`
  padding: 90px 0;

  @media (max-width: 1090px) {
    flex-direction: column;
    padding: 45px 0;
  }
`

const Press = ({ t }) => (
  <StyledPress id={t('nav.5.hash')}>
    <Wrap>
      <Flex align="flex-start" direction="column" justify="flex-start">
        <Title margin="0 0 10px">{t('press.title')}</Title>

        <RawText margin="0 0 22px" dangerouslySetInnerHTML={{ __html: t('press.description') }} />

        <List align="center">
          {t('press.items').map(item => (
            <PressItem
              key={Math.random()}
              img={require(`../../assets/press/${item.img.name}`)}
              description={item.description}
              title={item.title}
              alt={item.img.alt}
              href={item.href}
            />
          ))}
        </List>
      </Flex>
    </Wrap>
  </StyledPress>
)

Press.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Press)
