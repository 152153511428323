import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const Block = styled.div`
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  border-bottom: ${props => (props.withBorder ? `1px solid ${colors.lightGrey}` : '0')};
  padding: ${props => (props.padding ? props.padding : '0')};

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 15px;
    margin: 0;
  }
`

const Wrap = ({ children, ...props }) => <Block {...props}>{children}</Block>

Wrap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Wrap
