/* eslint-disable */

/**
 * Print the debug banner to console
 * Includes build information and credits links
 *
 * @export
 * @param {object} o options object
 * @param {string} o.version version string from package.json
 * @param {number} o.build unique build number from CI
 * @param {string} o.hash commit hash identifying for the source code
 * @param {string} o.env environment name (production, homologation, dev)
 */
export function debugBanner({ version, build, hash, env } = {}) {
  const buildInfo = getBuildInfo({ version, build, hash, env });
  console.info(
    `%c Created by %c PushStart %c http://pushst.art %c %c %c %c ${buildInfo}`,
    "background-color: #000000; color: #FFFFFF; line-height: 1.6em",
    "background-color: #6D3581; color: #FFFFFF; line-height: 1.6em; font-weight: bold",
    "background-color: none; color: #0000EE",
    "background-color: #FF6723; color: #FFFFFF",
    "background-color: #2A8DFF; color: #FFFFFF",
    "background-color: #FFAF00; color: #FFFFFF",
    "background-color: none; color: #AAAAAA"
  );
}

export function getBuildInfo({ version, build, hash, env } = {}) {
  const buildInfoParts = [];

  if (version) {
    buildInfoParts.push(`v${version}`);
  }

  if (env) {
    buildInfoParts.push(
      String(env)
        .substr(0, 1)
        .toUpperCase()
    );
  }

  if (build) {
    buildInfoParts.push(build);
  }

  if (hash) {
    buildInfoParts.push(
      String(hash)
        .substr(0, 6)
        .toLowerCase()
    );
  }

  const buildInfo = buildInfoParts.join("-");

  return buildInfo;
}
