import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withNamespaces } from 'react-i18next'
import colors from '../../theme/colors'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: ${props => `${props.width}px`};

  input,
  textarea {
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor};
    background: transparent;
    font-size: 15px;
    font-weight: 300;
    color: ${props => props.fontColor};
    padding-bottom: 5px;
    margin-bottom: 30px;
  }

  p {
    margin: 10px 0 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  textarea {
    border: 1px solid ${props => props.borderColor};
    height: ${props => `${props.textareaHeight}px`};
    resize: none;
    padding: 10px;
  }

  @media (max-width: ${props => `${props.width + 20}px`}) {
    width: 100%;
  }

  button[type='submit'] {
    transition: 0.4s width ease, 0.4s height ease, 0.4s transform ease, 0.4s color ease,
      0.4s border ease;
    ${props => props.valid
      && css`
        border-color: ${colors.white};
        color: ${colors.white};
      `}
    ${props => props.loading
      && css`
        pointer-events: none;
        font-size: 0px;
        border-top-color: transparent;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        animation: ${spin} 1s linear 0s infinite;
      `}
  }
`

class Form extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    width: PropTypes.number,
    textareaHeight: PropTypes.number,
    borderColor: PropTypes.string,
    fontColor: PropTypes.string,
    url(props, propName) {
      if (props.ajax === true && (props[propName] === '' || typeof props[propName] !== 'string')) {
        return new Error('Please provide a url!')
      }
      return null
    },
    ajax: PropTypes.bool,
  }

  static defaultProps = {
    width: 490,
    borderColor: colors.lightGrey,
    fontColor: colors.lightGrey,
    textareaHeight: 100,
    url: '',
    ajax: false,
  }

  state = {
    loading: false,
  }

  componentDidMount() {
    this.elForm = React.createRef()

    const { children } = this.props
    let newState = {}

    React.Children.map(children, (child) => {
      if (child.type === 'input' || child.type === 'select') {
        newState = {
          [child.props.name]: child.props.defaultValue || '',
        }
      }

      return this.setState(newState)
    })
  }

  checkValidity = () => {
    const form = this.elForm.current

    return Array.from(form.elements)
      .filter(element => element.type !== 'submit')
      .every(element => element.type !== 'submit' && element.required && element.validity.valid)
  }

  handleChange = (e) => {
    const validity = this.checkValidity()

    this.setState({
      [e.target.name]: e.target.value,
      valid: validity,
    })
  }

  send = async (e) => {
    const { ajax, url, t } = this.props
    if (!ajax) return false

    const { loading: _, ...fields } = this.state
    e.preventDefault()

    this.setState({ loading: true })

    const data = new FormData()

    Object.keys(fields).forEach(key => data.append(key, fields[key]))

    const res = await axios.post(url, data)

    this.setState({ loading: false })

    if (res) {
      alert(t('alert_success'))
      return Object.keys(fields).forEach(key => this.setState({ [key]: '' })) // cleaning form
    }

    return alert(t('alert_error'))
  }

  render() {
    const { children, ...props } = this.props
    const { loading, valid } = this.state

    return (
      <StyledForm {...props} onSubmit={this.send} loading={loading} ref={this.elForm} valid={valid}>
        {React.Children.map(children, (child) => {
          const fieldTags = ['input', 'select', 'textarea']

          if (fieldTags.indexOf(child.type) !== -1) {
            const { ...st } = this.state
            return React.cloneElement(child, {
              onChange: this.handleChange,
              value: st[child.props.name],
            })
          }
          return child
        })}
      </StyledForm>
    )
  }
}

export default withNamespaces()(Form)
