import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const Btn = styled(({ component, isBigger: _, ...props }) => React.cloneElement(component, props))`
  color: ${props => props.color};
  font-size: ${props => (props.isBigger ? '17px' : '15px')};
  padding: 10px 20px;
  border: ${props => (props.isBigger ? '2px' : '1.5px')} solid ${props => props.color};
  background: transparent;
  align-self: flex-start;
  cursor: pointer;
`

Btn.propTypes = {
  color: PropTypes.string,
  component: PropTypes.node.isRequired,
  isBigger: PropTypes.bool,
}

Btn.defaultProps = {
  color: colors.lightGrey,
  isBigger: false,
}

export default Btn
