import React from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import SmoothScroll from 'smooth-scroll'
import Wrap from '../wrap/wrap'
import Logo from '../logo/logo'
import Nav from '../nav/nav'
import colors from '../../theme/colors'

const StyledHeader = styled.header`
  width: 100%;
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  background: ${props => (props.headerIsOnTop ? 'transparent' : colors.white)};
  transition: 0.4s background ease;
  z-index: 2;
  box-shadow: ${props => (props.headerIsOnTop ? '0' : '0px 3px 5px 0px rgba(0,0,0,0.2)')};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

@inject('appStore')
@observer
class Header extends React.Component {
  static propTypes = {
    appStore: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.scrollInstance = new SmoothScroll('[data-scroll]', {
      offset: 125,
      speedAsDuration: true,
      speed: 500,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset

    const { appStore } = this.props

    appStore.toggleHeader(scrollY === 0)
  }

  render() {
    const { appStore } = this.props

    return (
      <StyledHeader headerIsOnTop={appStore.headerIsOnTop}>
        <Wrap>
          <HeaderContainer>
            <a data-scroll href="#home">
              <Logo headerIsOnTop={appStore.headerIsOnTop} />
            </a>
            <Nav />
          </HeaderContainer>
        </Wrap>
      </StyledHeader>
    )
  }
}

export default Header
