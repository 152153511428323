import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import colors from '../../theme/colors';
import Wrap from '../wrap/wrap';
import Title from '../title/title';
import RawText from '../rawText/rawText';
import Texts from '../texts/texts';
import SocialMedia from '../socialMedia/socialMedia';
import Form from '../form/form';
import Btn from '../btn/btn';
import FlexBase from '../flex/flex';

import { Envelope, Whatsapp } from '../icons/icons'


const StyledFooter = styled.footer`
  width: 100%;
`;

const Top = styled.div`
  width: 100%
  padding: 40px 0 100px
  background: ${colors.mineShaft}
`;

const Copyright = styled.div`
  width: 100%
  padding: 17px 10px
  background: ${colors.coal}
  display: flex
  align-items: center
  justify-content: center

  p {
    font-size: 13px
  }
`;

const Address = styled(RawText).attrs({
  as: 'a'
})`
  color: ${colors.grey}
  margin-top: 10px
  font-family: CooperHewitt
  font-weight: 350
`;

const ContactInfo = styled(RawText).attrs({
  as: 'a'
})`
  color: ${colors.grey}
  font-weight: 600
  font-family: CooperHewitt
`;

const Hr = styled.hr`
  width: 46px
  height: 2px
  background: ${colors.white}
  opacity: 0.15
  margin: 26px 0 13px
`;

const Flex = styled(FlexBase)`
  @media (max-width: 1090px) {
    flex-direction: column
    align-items: center

    form {
      margin-top: 30px;
    }
  }
`;

const ContactInfoSocial = styled(ContactInfo)`
  color: ${colors.white}
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  svg {
    width: 1.5em;
    height: auto;
    color: ${colors.white}
    margin-bottom: -0.5em;
    margin-right: 0.5em;
  }
`;

const DNA = styled.img.attrs({
  src: require("../../assets/images/DNA.png"),
  alt: "Selo de Análise DNA - Diretório Nacional da Advocacia",
  title: "Selo de Análise DNA - Diretório Nacional da Advocacia"
})`
  width: 100%;
`;
const Footer = ({ t }) => {
  const addresses = t('footer.addresses') || [];
  return (
    <StyledFooter id={t('nav.6.hash')}>
      <Top>
        <Wrap>
          <Flex align="center" justify="space-between">
            <Texts width="260px">
              <Title color={colors.white}>{t('footer.title')}</Title>

              <RawText
                style={{ fontFamily: 'CooperHewitt' }}
                color={colors.lightGrey}
              >
                <b>{t('company_name')}</b>
              </RawText>

              {addresses
                ? addresses.map((address, index) => (
                    <>
                      {index > 0 ? <Hr /> : null}
                      <Address
                        rel="noopener noreferrer"
                        target="_blank"
                        href={address.gmaps}
                        dangerouslySetInnerHTML={{ __html: address.street }}
                      />
                      <ContactInfo
                        href={`tel:${String(address.phone).replace(
                          /[.()\s]/gi,
                          ""
                        )}`}
                      >
                        {address.phone}
                      </ContactInfo>
                    </>
                  ))
                : null}

              <Hr />

              <ContactInfoSocial href="https://api.whatsapp.com/send?phone=5511992841037" target="_BLANK">
                <Whatsapp /> +55 (11) 99284.1037
              </ContactInfoSocial>

              <ContactInfoSocial href="mailto:contato@dbtesser.com.br">
                <Envelope /> contato@dbtesser.com.br
              </ContactInfoSocial>


              <SocialMedia />

              <Hr />

              <DNA />
            </Texts>

            <Form
              url="./send.php"
              ajax
              borderColor={colors.darkGrey}
              fontColor={colors.darkGrey}
            >
              <input type="hidden" name="subject" defaultValue="1" />
              {t('footer.fields').map(field => React.createElement(field.element, {
                  key: Math.random(),
                  ...field.props
                }))}
              <Btn
                color={colors.darkGrey}
                component={<button type="submit">{t('send')}</button>}
              />
            </Form>
          </Flex>
        </Wrap>
      </Top>
      <Copyright>
        <RawText align="center" color={colors.grey}>
          {`© ${new Date().getFullYear()} ${t('copyright')} `}
          <a
            href="http://pushstart.com.br/"
            rel="noopener noreferrer"
            target="_blank"
          >
            PushStart
          </a>
        </RawText>
      </Copyright>
    </StyledFooter>
  );
};

Footer.propTypes = {
  t: PropTypes.func.isRequired
};

export default withNamespaces()(Footer);
