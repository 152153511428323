import React from 'react'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import PropTypes from 'prop-types'
import Texts from '../texts/texts'
import RawText from '../rawText/rawText'
import Title from '../title/title'
import Wrap from '../wrap/wrap'
import FlexBase from '../flex/flex'
import partners from '../../assets/images/partners.png'
import partners2 from '../../assets/images/partners_2.png'
import colors from '../../theme/colors'

const Images = styled.div`
  display: flex;
  flex-direction: column;

  img + img {
    margin-top: 55px;
  }
`

const Flex = styled(FlexBase)`
  padding: 0 0 110px;
  border-bottom: 1px solid ${colors.lightGrey};

  @media (max-width: 1129px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 0 55px;

    ${Images} {
      margin-top: 30px;
    }
  }
`

const StyledHistory = styled.section`
  width: 100%;
  padding: 110px 0 100px;

  @media (max-width: 1129px) {
    padding: 55px 0 50px;
  }
`

const History = ({ t }) => (
  <StyledHistory>
    <Wrap>
      <Flex align="center" justify="space-between">
        <Images>
          <img src={partners} alt={t('history.alt.0')} />
          <img src={partners2} alt={t('history.alt.1')} />
        </Images>

        <Texts width="460px">
          <Title margin="0 0 20px 0">{t('history.title')}</Title>
          {t('history.paragraphs').map(p => (
            <RawText key={Math.random()} dangerouslySetInnerHTML={{ __html: p }} />
          ))}
        </Texts>
      </Flex>
    </Wrap>
  </StyledHistory>
)

History.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(History)
