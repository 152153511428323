import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import RawText from '../rawText/rawText'
import Title from '../title/title'
import Wrap from '../wrap/wrap'
import List from '../list/list'
import Member from '../member/member'
import colors from '../../theme/colors'

const StyledTeam = styled.section`
  width: 100%;
`

const PaddingBlock = styled.div`
  padding: 90px 0;
  border-bottom: 1px solid ${colors.lightGrey};

  @media (max-width: 1090px) {
    padding: 45px 0;
  }
`

const Team = ({ t }) => (
  <StyledTeam id={t('nav.4.hash')}>
    <Wrap>
      <PaddingBlock>
        <Title margin="0 0 10px">{t('team.title')}</Title>

        <RawText margin="0 0 40px">{t('team.description')}</RawText>

        <List align="center">
          {t('team.members')
            .filter(member => member.isBusinessPartner)
            .map(member => (
              <Member
                key={member.id}
                id={member.id}
                img={member.img}
                alt={t('team.stdAlt')}
                name={member.name}
                title={member.title}
              />
            ))}
        </List>
        <List align="center">
          {t('team.members')
            .filter(member => !member.isBusinessPartner)
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(member => (
              <Member
                key={member.id}
                id={member.id}
                img={member.img}
                alt={t('team.stdAlt')}
                name={member.name}
                title={member.title}
              />
            ))}
        </List>
      </PaddingBlock>
    </Wrap>
  </StyledTeam>
)

Team.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(Team)
