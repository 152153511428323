import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const StyledSubtitle = styled.h2`
  font-size: 18px;
  color: ${props => props.color};
  font-weight: 600;
  margin: ${props => props.margin};
`

const Subtitle = ({ children, ...rest }) => <StyledSubtitle {...rest}>{children}</StyledSubtitle>

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.string,
  margin: PropTypes.string,
}

Subtitle.defaultProps = {
  color: colors.darkGrey,
  margin: '10px 0 40px',
}

export default Subtitle
