import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from '../../theme/colors'

const StyledLogo = styled.svg`
  width: 173px;
  height: 26px;
  fill: ${props => (props.headerIsOnTop ? colors.white : colors.black)};
  transition: 0.4s fill ease;

  @media (max-width: 320px) {
    .words {
      display: none;
    }
  }
`

const Logo = ({ headerIsOnTop }) => (
  <StyledLogo
    id="bbeb2ef2-34d3-417d-9c37-07fff7f4cff6"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 173.55307 26.08002"
    headerIsOnTop={headerIsOnTop}
  >
    <title>Logo</title>
    <g className="words">
      <path
        d="M66.01,5.04a7.52186,7.52186,0,0,0-5.94-2.3H54.63v20.6h5.43A7.52189,7.52189,0,0,0,66,21.04c1.36-1.53,2.05-4.02,2.05-7.47V12.51C68.05,9.06,67.36,6.57,66.01,5.04Zm.09,8.53q0,4.275-1.57,6.16A5.5993,5.5993,0,0,1,59.91,21.62H56.53l.01-17.16h3.38a5.62081,5.62081,0,0,1,4.62,1.88C65.58,7.61,66.1,9.66,66.1,12.51Z"
        transform="translate(0 0.00001)"
      />
      <path
        d="M82.7,17.9v-.53a4.53064,4.53064,0,0,0-3.59-4.68,4.15326,4.15326,0,0,0,3.06-4.25V7.91a4.526,4.526,0,0,0-1.73-3.92,7.50031,7.50031,0,0,0-4.53-1.25H70.67v20.6h5.65a7.77707,7.77707,0,0,0,4.6-1.35A4.78915,4.78915,0,0,0,82.7,17.9ZM72.57,4.46h3.41c.08,0,.15-.01.23-.01a5.01616,5.01616,0,0,1,2.82.87,3.05089,3.05089,0,0,1,1.18,2.66v.41a3.08007,3.08007,0,0,1-1.18,2.67,5.15114,5.15114,0,0,1-3.05.85H72.57ZM80.73,17.8a3.35728,3.35728,0,0,1-1.2,2.86,4.77913,4.77913,0,0,1-3.07.96H72.57v-8h3.89l-.01-.01a4.85,4.85,0,0,1,3.08.92,3.43637,3.43637,0,0,1,1.2,2.86Z"
        transform="translate(0 0.00001)"
      />
      <polygon points="86.346 4.456 91.592 4.456 91.592 23.336 93.492 23.336 93.492 4.456 98.726 4.456 98.726 2.739 86.346 2.739 86.346 4.456" />
      <polygon points="101.321 23.332 112.809 23.332 112.809 21.619 103.222 21.619 103.222 13.667 111.48 13.667 111.48 11.979 103.222 11.979 103.222 4.456 112.809 4.456 112.809 2.739 101.321 2.739 101.321 23.332" />
      <path
        d="M122.533,11.962a7.39214,7.39214,0,0,1-3.819-1.337,3.37294,3.37294,0,0,1-1.1-2.678V7.73a3.11081,3.11081,0,0,1,1.227-2.6,5.00825,5.00825,0,0,1,3.181-.944h.184A4.20006,4.20006,0,0,1,125.652,5.494a6.69977,6.69977,0,0,1,1.071,4.24h1.9V9.096a7.00672,7.00672,0,0,0-1.558-4.951q-1.562-1.67606-4.939-1.676a7.07113,7.07113,0,0,0-4.714,1.48,5.00011,5.00011,0,0,0-1.758,4.039v.266q0,4.46906,6.133,5.315a8.14167,8.14167,0,0,1,3.896,1.33,3.4,3.4,0,0,1,1.243,2.821v.237a3.58182,3.58182,0,0,1-1.267,2.9,5.1472,5.1472,0,0,1-3.41,1.059h-.184a4.54991,4.54991,0,0,1-3.446-1.337,5.488,5.488,0,0,1-1.28-3.945h-1.9v.262a6.91621,6.91621,0,0,0,1.729,4.98,6.63389,6.63389,0,0,0,4.98,1.758,7.30906,7.30906,0,0,0,4.906-1.545,5.31193,5.31193,0,0,0,1.811-4.268v-.262a4.84079,4.84079,0,0,0-1.5-3.778A8.8789,8.8789,0,0,0,122.533,11.962Z"
        transform="translate(0 0.00001)"
      />
      <path
        d="M138.56,11.966a7.39149,7.39149,0,0,1-3.818-1.337,3.38491,3.38491,0,0,1-1.1-2.678v-.2a3.124,3.124,0,0,1,1.227-2.6,5.01626,5.01626,0,0,1,3.168-.965h.184A4.20016,4.20016,0,0,1,141.667,5.494a6.69973,6.69973,0,0,1,1.071,4.24h1.9V9.096a7.006,7.006,0,0,0-1.561-4.951q-1.562-1.67606-4.939-1.676a7.07208,7.07208,0,0,0-4.715,1.48,5.00787,5.00787,0,0,0-1.758,4.039v.266q.021,4.46906,6.153,5.315a8.31148,8.31148,0,0,1,3.916,1.327,3.4,3.4,0,0,1,1.177,2.817v.237a3.56874,3.56874,0,0,1-1.267,2.9,5.135,5.135,0,0,1-3.406,1.059h-.184a4.538,4.538,0,0,1-3.442-1.337,5.47684,5.47684,0,0,1-1.284-3.945h-1.9v.262a6.80041,6.80041,0,0,0,1.729,4.98,6.6762,6.6762,0,0,0,4.985,1.76495,7.34255,7.34255,0,0,0,4.943-1.54095,5.3169,5.3169,0,0,0,1.807-4.268v-.262a4.841,4.841,0,0,0-1.5-3.778A8.8793,8.8793,0,0,0,138.56,11.966Z"
        transform="translate(0 0.00001)"
      />
      <polygon points="147.458 23.332 158.946 23.332 158.946 21.619 149.363 21.619 149.363 13.667 157.626 13.667 157.626 11.979 149.363 11.979 149.363 4.456 158.946 4.456 158.946 2.739 147.458 2.739 147.458 23.332" />
      <path
        d="M173.55,9.11V8.58a6.87238,6.87238,0,0,0-.55-2.9,4.08776,4.08776,0,0,0-1.52-1.82,6.93349,6.93349,0,0,0-2-.85,8.43353,8.43353,0,0,0-2.24-.27h-5.7v20.6h1.9V14.97l3.8-.03c.43,0,.74-.03.95-.03l3.28,8.4h2.08l-3.56-8.76C172.37,13.77,173.55,11.96,173.55,9.11Zm-1.95-.06a5.10077,5.10077,0,0,1-.41,2.14,2.95545,2.95545,0,0,1-1.1,1.3,5.08257,5.08257,0,0,1-1.35.59,5.84614,5.84614,0,0,1-1.44.17h-3.86l-.01-8.8h3.85a5.79,5.79,0,0,1,1.43994.17A4.87252,4.87252,0,0,1,170.09,5.2a2.95578,2.95578,0,0,1,1.1,1.3,5.101,5.101,0,0,1,.41,2.14Z"
        transform="translate(0 0.00001)"
      />
    </g>
    <path
      d="M0,0V26.08H41.01V0ZM24.17,24.17H1.91V1.91H24.17Zm14.93,0H26.08V11.14H39.12ZM39.12,9.23H26.08V1.91H39.12Z"
      transform="translate(0 0.00001)"
    />
  </StyledLogo>
)

Logo.propTypes = {
  headerIsOnTop: PropTypes.bool.isRequired,
}

export default Logo
