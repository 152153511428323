import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Svg = styled.svg`
  width: 9px;
  height: 7px;
  color: ${props => props.color};
  transition: 0.4s color ease;
`

const Arrow = ({ color }) => (
  <Svg
    color={color}
    id="a79df180-4bfc-4a5b-8979-66ca666245c5"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 286.13138 167.04997"
  >
    <title>arrow</title>
    <path
      fill="currentColor"
      d="M279,172.475H41c-21.4,0-32.1,25.9-17,41l119.1,119a23.901,23.901,0,0,0,33.9,0l119-119C311.1,198.375,300.4,172.475,279,172.475Z"
      transform="translate(-16.93431 -172.47501)"
    />
  </Svg>
)

Arrow.defaultProps = {
  color: '#000',
}

Arrow.propTypes = {
  color: PropTypes.string,
}

export default Arrow
