import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import FlexBase from '../flex/flex'
import Title from '../title/title'
import RawText from '../rawText/rawText'
import Form from '../form/form'
import Btn from '../btn/btn'
import work from '../../assets/images/work.png'
import Wrap from '../wrap/wrap'

const Section = styled.section`
  width: 100%;
  padding: 83px 0 110px;

  @media (max-width: 1090px) {
    padding: 41px 0 55px;
  }
`

const Flex = styled(FlexBase)`
  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 30px;
    }
  }
`

const WorkWithUs = ({ t }) => (
  <Section>
    <Wrap>
      <Flex align="center" justify="space-between">
        <Form ajax url="./send.php">
          <Title>{t('workWithUs.title')}</Title>
          <RawText>{t('workWithUs.description')}</RawText>
          <input type="hidden" name="subject" defaultValue="2" />
          {t('workWithUs.fields').map(field => React.createElement(field.element, { key: Math.random(), ...field.props }))}
          <Btn component={<button type="submit">{t('send')}</button>} />
        </Form>
        <img src={work} alt={t('workWithUs')} />
      </Flex>
    </Wrap>
  </Section>
)

WorkWithUs.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces()(WorkWithUs)
